





















































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { NewAccount } from "@/types";
import { ValidMimeTypes } from "@/assets/classes/ValidMimeTypes";
import RequestHandler from "@/assets/ts/requestHandler";
import DocumentationHandler from "@/assets/ts/documentationHandler";
import VuePhoneNumberInput from "vue-phone-number-input";
import smartyStreets from "@/components/smarty-streets/smarty-streets.vue";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

const RH = new RequestHandler();
const DH = new DocumentationHandler();

@Component({
  components: {
    VuePhoneNumberInput,
    smartyStreets
  }
})
export default class Signup extends Vue {
  private currentStep = 1;
  private mvdsCustomerID = -1;
  private emailAtLoad = "";

  private useEnteredAddress = false;
  private configLoaded = false;
  private config = {};

  private editAccount: NewAccount = {
    primaryUser: {
      id: 0,
      username: "",
      email: "",
      fullname: "",
      password: ""
    },
    mvdsCustomerType: {
      mvdsCustomerTypeID: 0
    },
    vtrsCustomer: {
      customerType: "",
      customerName: "",
      customerEmail: "",
      contactPhone: "",
      businessCustomer: { businessName: "" },
      physicalAddress: {
        streetNo: "",
        address1: "",
        city: "",
        state: "",
        postalCode: "",
        zip5: "",
        countyID: -1
      }
    },
    mvdsCustomerID: 0,
    accountName: "",
    accountNo: "",
    routingNo: "",
    statusCode: "P",
    digitalSignature: ""
  };

  private userInfo = {
    showPass: false,
    firstName: "",
    lastName: "",
    stateBoxItems: [],
    businessTypeBoxItems: [] as {}[],
    valid: true
  };

  private address = {
    address1: "",
    zipCode: "",
    valid: true
  };

  private bankInfo = {
    bankName: "",
    confirmAccountNo: "",
    valid: true,
    useCreditCard: false,
    routingNoErrorMsg: "",
    accountNoErrorMsg: "",
    confirmAccountNoErrorMsg: "",
    accountNameErrorMsg: ""
  };

  private submitEdit = {
    errorMessage: "",
    showError: false,
    loading: false
  };

  private businessLicense = {
    url: "",
    newFile: [] as any,
    docOrDocx: false,
    errorMessage: ""
  };
  private statement = {
    url: "",
    newFile: [] as any,
    docOrDocx: false,
    errorMessage: ""
  };

  private smartyStreetsVuetifyProps = {
    outlined: true,
    dense: true
  };

  private rules = {
    required: (value: string | number) => !!value || "This field is required.",
    password: (value: string) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-])[A-Za-z\d~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-]{8,}$/;
      return (
        pattern.test(value) ||
        "Must contain one lowercase letter, one uppercase letter, one number, one special character, and be at least eight characters in total"
      );
    },
    email: (value: string) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail address.";
    },
    zipcode: (value: string) => {
      const pattern = /^\d{5}(-\d{4})?$/;
      return pattern.test(value) || "Invalid zip code.";
    },
    phone: (value: string) => {
      const pattern = /^\d{10}$/;
      return pattern.test(value) || "Invalid phone number.";
    },
    dropdown: (value: number) =>
      (!!value && value != -1) || "This field is required."
  };

  private validateAddress() {
    if (
      document.querySelector(".error--text input") === undefined ||
      document.querySelector(".error--text input") === null
    ) {
      this.currentStep++;
    }
  }

  private async validateUserInfo() {
    let emailExists = true;
    if (this.userInfoForm.validate()) {
      try {
        emailExists = await RH.checkForExistence(
          "email",
          this.editAccount.primaryUser.email
        );
        if (
          emailExists &&
          this.editAccount.primaryUser.email !== this.emailAtLoad
        ) {
          this.$root.$emit(
            "snackbar-message",
            "Email is taken. Please choose another."
          );
        } else {
          this.currentStep++;
        }
      } catch (e) {
        this.$root.$emit(
          "snackbar-message",
          "Error checking to determine if email is unique."
        );
      }
    }
  }

  private async validateBankInfo() {
    if (
      [null, ""].includes(this.editAccount.accountNo) &&
      [null, ""].includes(this.editAccount.routingNo) &&
      [null, ""].includes(this.editAccount.accountName) &&
      [null, ""].includes(this.bankInfo.confirmAccountNo) &&
      this.bankInfo.useCreditCard
    ) {
      this.clearBankFields();
      this.bankInfo.valid = true;
    } else if (
      ![null, ""].includes(this.editAccount.accountNo) &&
      ![null, ""].includes(this.editAccount.routingNo) &&
      ![null, ""].includes(this.editAccount.accountName) &&
      ![null, ""].includes(this.bankInfo.confirmAccountNo) &&
      this.editAccount.accountNo === this.bankInfo.confirmAccountNo
    ) {
      this.validateRoutingNo();
      this.validateAccountNo();
      if (
        this.bankInfo.routingNoErrorMsg === "" &&
        this.bankInfo.accountNoErrorMsg === ""
      ) {
        Object.assign(this.bankInfo, {
          accountNoErrorMsg: "",
          accountNameErrorMsg: "",
          confirmAccountNoErrorMsg: "",
          valid: true
        });
      } else {
        this.bankInfo.valid = false;
      }
    } else {
      Object.assign(this.bankInfo, {
        accountNoErrorMsg: "",
        routingNoErrorMsg: "",
        accountNameErrorMsg: "",
        confirmAccountNoErrorMsg: ""
      });
      if ([null, ""].includes(this.editAccount.accountNo)) {
        this.bankInfo.accountNoErrorMsg = "Please enter an account number.";
      } else {
        this.validateAccountNo();
      }
      if ([null, ""].includes(this.editAccount.routingNo)) {
        Object.assign(this.bankInfo, {
          bankName: "",
          routingNoErrorMsg: "Please enter a routing number."
        });
      } else {
        this.validateRoutingNo();
      }
      if ([null, ""].includes(this.editAccount.accountName)) {
        this.bankInfo.accountNameErrorMsg = "Please enter an account name.";
      }
      if ([null, ""].includes(this.bankInfo.confirmAccountNo)) {
        this.bankInfo.confirmAccountNoErrorMsg =
          "Please confirm the account number";
      } else if (
        this.bankInfo.confirmAccountNo !== this.editAccount.accountNo
      ) {
        this.bankInfo.confirmAccountNoErrorMsg =
          "This value must be the same as the account number.";
      }
      this.bankInfo.valid = false;
    }
  }

  private async validateRoutingNo() {
    try {
      const response = await RH.validateRoutingNo(this.editAccount.routingNo);
      this.bankInfo.bankName = response.bankName;
      this.bankInfo.routingNoErrorMsg = "";
    } catch (e) {
      this.bankInfo.bankName = "";
      this.bankInfo.routingNoErrorMsg = "Invalid routing number.";
    }
  }

  private validateAccountNo() {
    const pattern = /^[a-zA-Z0-9\\*]+$/;
    if (pattern.test(this.editAccount.accountNo)) {
      this.bankInfo.accountNoErrorMsg = "";
    } else {
      this.bankInfo.accountNoErrorMsg =
        "Account numbers may contain only letters and numbers.";
    }
  }

  private checkBankValidation() {
    this.validateBankInfo();
    if (this.bankInfo.valid) {
      this.currentStep++;
    }
  }

  get userInfoForm(): Vue & { validate: () => boolean } {
    return this.$refs.userInfoForm as Vue & { validate: () => boolean };
  }

  get addressForm(): Vue & { validate: () => boolean } {
    return this.$refs.addressForm as Vue & { validate: () => boolean };
  }

  get bankInfoForm(): Vue & { validate: () => boolean } {
    return this.$refs.bankInfoForm as Vue & { validate: () => boolean };
  }

  private async submitUser(): Promise<void> {
    this.submitEdit.loading = true;
    const vtrsCust = this.editAccount.vtrsCustomer;
    this.editAccount.primaryUser.fullname =
      this.userInfo.firstName + " " + this.userInfo.lastName;
    vtrsCust.customerName = vtrsCust.businessCustomer.businessName;
    vtrsCust.customerEmail = this.editAccount.primaryUser.email;
    const firstSpaceAddress = this.address.address1.indexOf(" ");
    vtrsCust.physicalAddress.streetNo = this.address.address1.slice(
      0,
      firstSpaceAddress
    );
    vtrsCust.physicalAddress.address1 = this.address.address1.slice(
      firstSpaceAddress + 1
    );
    vtrsCust.physicalAddress.postalCode = vtrsCust.physicalAddress.zip5;

    let userID = 0;
    try {
      userID = (await RH.editCustomer(this.editAccount)).mvdsCustomerID;
    } catch (e) {
      Object.assign(this.submitEdit, {
        errorMessage: "Edit failed due to a server error.",
        showError: true,
        loading: false
      });
      return;
    }

    this.$root.$emit("snackbar-message", "Account Edited Successfully");

    try {
      await RH.updateAccountStatusNonAdmin(userID.toString(), "P", "Pending");
      this.$root.$emit(
        "snackbar-message",
        "Account edit submitted successfully. Your account has been marked pending and is awaiting approval."
      );
      this.$router.push({ name: "Login" });
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "Account edit submitted successfully, but your account was not set to pending status. Please try again."
      );
    } finally {
      this.submitEdit.loading = false;
    }
  }

  private async loadUser() {
    this.editAccount = await RH.getUserCustomerInfo(
      this.$store.getters.userInfo.userID
    );
    this.emailAtLoad = this.editAccount.primaryUser.email;
    const firstSpace = this.editAccount.primaryUser.fullname.indexOf(" ");
    this.userInfo.firstName = this.editAccount.primaryUser.fullname.slice(
      0,
      firstSpace
    );
    this.userInfo.lastName = this.editAccount.primaryUser.fullname.slice(
      firstSpace + 1
    );
    this.address.address1 =
      this.editAccount.vtrsCustomer.physicalAddress.streetNo +
      " " +
      this.editAccount.vtrsCustomer.physicalAddress.address1;
    this.bankInfo.useCreditCard =
      [null, ""].includes(this.editAccount.accountNo) ||
      [null, ""].includes(this.editAccount.routingNo);

    if (!this.editAccount.routingNo === null) {
      this.validateRoutingNo();
    }
    this.bankInfo.confirmAccountNo = this.editAccount.accountNo;
    this.loadDocumentation();
  }

  private async loadDocumentation() {
    try {
      const businessLicenseBase64 = await RH.downloadDocument(
        this.mvdsCustomerID,
        "businessLicense"
      );
      this.businessLicense.docOrDocx = DH.checkForWordDoc(
        businessLicenseBase64
      );
      this.businessLicense.url = !this.businessLicense.docOrDocx
        ? businessLicenseBase64
        : "";

      const statementBase64 = await RH.downloadDocument(
        this.mvdsCustomerID,
        "statement"
      );
      this.statement.docOrDocx = DH.checkForWordDoc(statementBase64);
      this.statement.url = !this.statement.docOrDocx ? statementBase64 : "";
    } catch (e) {
      this.$root.$emit("snackbar-message", "Document retrieval failed");
    }
  }

  private async uploadDocument(document: any, docType: string) {
    this.businessLicense.errorMessage = "";
    this.statement.errorMessage = "";
    if (document.length !== 0) {
      if (ValidMimeTypes.types.includes(document.type)) {
        try {
          await RH.uploadDocument(
            Number(this.mvdsCustomerID),
            document,
            docType
          );
          const base64 = await RH.downloadDocument(
            Number(this.mvdsCustomerID),
            docType
          );
          if (docType === "businessLicense") {
            this.businessLicense.docOrDocx = DH.checkForWordDoc(base64);
            this.businessLicense.url = !this.businessLicense.docOrDocx
              ? base64
              : "";
          } else {
            this.statement.docOrDocx = DH.checkForWordDoc(base64);
            this.statement.url = !this.statement.docOrDocx ? base64 : "";
          }

          this.$root.$emit(
            "snackbar-message",
            "Document Uploaded Successfully"
          );
        } catch (e) {
          this.$root.$emit("snackbar-message", "Document Upload Failed");
        }
      } else {
        if (docType === "businessLicense") {
          this.businessLicense.errorMessage = "This file type is invalid";
        } else {
          this.statement.errorMessage = "This file type is invalid";
        }
      }
    } else {
      if (docType === "businessLicense") {
        this.businessLicense.errorMessage = "Please choose a file";
      } else {
        this.statement.errorMessage = "Please choose a file";
      }
    }
  }

  private async downloadDoc(docType: string) {
    try {
      if (docType === "businessLicense") {
        this.businessLicense.url = "";
        this.businessLicense.url = await RH.downloadDocument(
          Number(this.mvdsCustomerID),
          "businessLicense"
        );
      } else {
        this.statement.url = "";
        this.statement.url = await RH.downloadDocument(
          Number(this.mvdsCustomerID),
          "statement"
        );
      }
    } catch (e) {
      this.$root.$emit("snackbar-message", "Document Download Failed");
    }
  }

  private clearBankFields() {
    Object.assign(this.editAccount, {
      routingNo: "",
      accountNo: "",
      accountName: ""
    });
    Object.assign(this.bankInfo, {
      bankName: "",
      confirmAccountNo: "",
      routingNoErrorMsg: "",
      accountNoErrorMsg: "",
      accountNameErrorMsg: "",
      confirmAccountNoErrorMsg: ""
    });
  }

  async beforeCreate() {
    this.config = await RH.getSmartyStreetsConfig();
    this.configLoaded = true;
  }

  async created() {
    this.mvdsCustomerID = (
      await RH.getUserCustomerInfo(this.$store.getters.userInfo.userID)
    ).mvdsCustomerID;
    this.userInfo.stateBoxItems = await RH.getStates();
    this.userInfo.businessTypeBoxItems = await RH.getCustomerTypes();
    this.loadUser();
  }
}
